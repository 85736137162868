import React, {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import {getRandomNumber} from "../../../../helpers/misc.ts";
import validate from "../../../../helpers/validate.ts";
import styles from './ChangePasswordPage.module.scss';

import mainStore from "../../../../store/main.ts";
import Icon from '../../../../components/Icon';
import { showDangerToast, showSuccessToast } from '../../../../helpers/toasts.helper.ts';
import {
  changePasswordApiService,
  IChangePasswordPayload
} from '../../../../services/change-password/change-password-api.service.ts';
import classNames from 'classnames';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';

const initialFormFirst = {
  password: "",
};

const initialFormSecond = {
  password: "",
  repeatPassword: "",
};

export default function AppChangePasswordPage() {
  const $navigate = useNavigate();
  const [formFirstState, setFirstFormState] = useState<typeof initialFormFirst>(initialFormFirst);
  const [formSecondState, setSecondFormState] = useState<typeof initialFormSecond>(initialFormSecond);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [changePasswordStep, setChangePasswordStep] = useState<'first' | 'second'>('first');
  const [hiddenPasswords, setHiddenPasswords] = useState(true);
  const breakpoint = useBreakpoint();
  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);

  const handleCheckPasswordForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCheckPassword();
  };

  const handleChangePasswordForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleChangePassword();
  };

  const handleCheckPassword = () => {
    if(isFormPending) return;
    
    for(const validation of [validate("password", formFirstState.password)]) {
      if(typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }
    setIsFormPending(true);
    changePasswordApiService.validatePassword(formFirstState.password)
      .then((res) => {
        console.log(res)
        if (res.valid) {
          showSuccessToast('Current password was successfully confirmed!');
          setHiddenPasswords(true);
          setChangePasswordStep('second');
        } else {
          showDangerToast('Incorrect current password!');
        }

      })
      .finally(() => setIsFormPending(false))
  }
  
  const handleChangePassword = () => {
    if(isFormPending) return;
    
    for (const validation of [
      validate("password", formSecondState.password, 'New password'),
      validate("password", formSecondState.repeatPassword, 'Confirm new password'),
    ]) {
      if(typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }
    
    if (formSecondState.password !== formSecondState.repeatPassword) {
      showDangerToast("Passwords don't match");
      return;
    }
    
    setIsFormPending(true);
    const payload: IChangePasswordPayload = {
      oldPassword: formFirstState.password,
      newPassword: formSecondState.password,
    };
    changePasswordApiService.changePassword(payload)
      .then(() => {
        showSuccessToast('Password was success changed!')
        $navigate(`/app/profile`);
      })
     .finally(() => setIsFormPending(false));
  }

  /* DOM */
  return (
    <>
      <div className={classNames(
        styles.Container,
        "tw-flex tw-flex-col tw-h-full tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl",
      )}>
        <div className={classNames(styles.PageForm, 'tw-h-full')}>
          <div className={styles.PageHeading}>
            Change password
          </div>

          <div className="tw-w-full tw-h-full">
            {changePasswordStep === 'first' &&
                <form
                    className={classNames(styles.FormFirstStep, 'tw-flex tw-flex-col tw-h-full')}
                    onSubmit={handleCheckPasswordForm}
                >
                    <div className="tw-flex-1">
                        <TextInput
                            type={hiddenPasswords ? 'password' : 'text'}
                            placeholder="Current password"
                            value={formFirstState.password}
                            onChange={(value) => setFirstFormState({ password: value })}
                            isBigger={isDesktop}
                            isReadOnly={isFormPending}
                            floatingPlaceholder={!isDesktop}
                        />
                        <Button
                            type="button"
                            additional={{ isZeroed: true }}
                            className="tw-w-max tw-h-max tw-p-0 tw-underline tw-mt-3 tw-text-xs xl:tw-text-sm xl:tw-mt-5"
                            onClick={() => setHiddenPasswords(prevState => !prevState)}
                        >
                          {hiddenPasswords ? 'Show password' : 'Hide password'}
                        </Button>
                    </div>

                  {isDesktop &&
                      <div className="tw-justify-end tw-flex">
                          <Button additional={{ isZeroed: true }} disabled={isFormPending}
                                  className="tw-text-secondary">
                              <span>Submit</span>
                            {!isFormPending ? (
                              <span className="btn-icon tw-bg-softGreen">
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                      </span>
                            ) : (
                              <Loader spinsPerSecond={3}/>
                            )}
                          </Button>
                      </div>
                  }
                </form>
            }

            {changePasswordStep === 'second' &&
                <form
                    className="tw-flex tw-flex-col tw-h-full tw-mt-3 xl:tw-mt-0"
                    onSubmit={handleChangePasswordForm}
                >
                    <p className={
                        classNames(
                          styles.ChangePasswordMessage,
                          'tw-font-semibold tw-text-sm xl:tw-text-base')
                    }>
                        You have entered the correct password. You can set a new password
                    </p>
                    <div className="tw-flex-1 tw-mt-4.5 xl:tw-mt-8.5">
                        <TextInput
                            type={hiddenPasswords ? 'password' : 'text'}
                            placeholder="New password"
                            value={formSecondState.password}
                            onChange={(value) => setSecondFormState({ ...formSecondState, password: value })}
                            isBigger={isDesktop}
                            isReadOnly={isFormPending}
                            floatingPlaceholder={!isDesktop}
                        />
                        <TextInput
                            containerClassName="tw-mt-4 xl:tw-mt-10"
                            placeholder="Confirm new password"
                            type={hiddenPasswords ? 'password' : 'text'}
                            value={formSecondState.repeatPassword}
                            onChange={(value) => setSecondFormState({ ...formSecondState, repeatPassword: value })}
                            isBigger={isDesktop}
                            isReadOnly={isFormPending}
                            floatingPlaceholder={!isDesktop}
                        />
                        <Button
                            type="button"
                            additional={{ isZeroed: true }}
                            className="tw-w-max tw-h-max tw-p-0 tw-underline tw-mt-3 tw-text-xs xl:tw-text-sm xl:tw-mt-5"
                            onClick={() => setHiddenPasswords(prevState => !prevState)}
                        >
                          {hiddenPasswords ? 'Show passwords' : 'Hide passwords'}
                        </Button>
                    </div>
                  {isDesktop &&
                      <div className="tw-flex tw-justify-end">
                          <Button
                              additional={{ isZeroed: true }}
                              disabled={isFormPending}
                              className="tw-text-secondary"
                          >
                              <span>Submit</span>
                            {!isFormPending ? (
                              <span className="btn-icon tw-bg-softGreen">
                        <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
                      </span>
                            ) : (
                              <Loader spinsPerSecond={3}/>
                            )}
                          </Button>
                      </div>
                  }
                </form>
            }
          </div>
        </div>
      </div>
      {!isDesktop &&
        <Button
          additional={{ isZeroed: true }}
          disabled={isFormPending}
          className="tw-text-secondary tw-mt-6 tw-ml-auto tw-text-base"
          type="button"
          onClick={changePasswordStep === 'first' ? handleCheckPassword : handleChangePassword}
        >
          <span>Submit</span>
          {!isFormPending ? (
            <span className="btn-icon tw-bg-softGreen">
              <Icon icon="arrow-right-lined-slim" className="tw-h-4"/>
            </span>
          ) : (
            <Loader spinsPerSecond={3}/>
          )}
        </Button>
      }
    </>
  );
}

import mainStore from "../../store/main.ts";
import { authApiService } from '../../services/auth-api/auth-api.service.ts';
import { navigationService } from '../../services/navigation.service.ts';

export default async function logout(withNavigation: boolean = true): Promise<void> {
  if (mainStore.accessToken) {
    try {
      await authApiService.logout();
    } catch (e) {

    }
  }
  clearLogoutData(withNavigation);
}

export const clearLogoutData = (withNavigation: boolean = true) => {
  mainStore.setRefreshToken(null);
  mainStore.setAccessToken(null);
  mainStore.setUser(null);
  if (withNavigation) navigationService.navigate('/login');
}

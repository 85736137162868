import { ICardBinDto } from './interfaces/card-bin.interface.ts';
import { CardType, IDepositDto } from '../card-api/interfaces';
import { AxiosResponse } from 'axios';
import { ICountryListDto } from './interfaces/country.interface.ts';
import { IAreaListDto } from './interfaces/area.interface.ts';
import { ICardKYCPayload, ISendCardKYCDto } from './interfaces/card-kyc.interface.ts';
import { apiClient } from '../../helpers/apiClient.ts';

export class CardKycApiService {
  private readonly _baseUrl: string = '/cards/kyc';

  public async getCardBins(): Promise<ICardBinDto[]> {
    const { data }  = await apiClient
      .get<{ cardBins: ICardBinDto[] }, AxiosResponse<{ cardBins: ICardBinDto[] }>>(`${this._baseUrl}/card_bins`);
    return data.cardBins ?? []
  }
  
  public async getDeposit(cardId: string): Promise<IDepositDto> {
    const { data } = await apiClient
      .get<IDepositDto, AxiosResponse<IDepositDto>>(`/cards/${cardId}/deposit`);
    return data;
  }
  
  public async getCountries(): Promise<ICountryListDto> {
    const { data } = await apiClient.get(`${this._baseUrl}/countries`) as AxiosResponse<ICountryListDto>;
    return data;
  }
  
  public async getAreas(cardType: CardType): Promise<IAreaListDto> {
    const { data } = await apiClient
      .get(`${this._baseUrl}/areas_headers`, { params: { cardType }}) as AxiosResponse<IAreaListDto>;
    return data;
  }
  
  public async sendCardKYC(payload: ICardKYCPayload): Promise<ISendCardKYCDto> {
    const { data } = await apiClient.post(`${this._baseUrl}`, payload) as AxiosResponse<ISendCardKYCDto>;
    return data;
  }
}

export const cardKycApiService: CardKycApiService = new CardKycApiService();

import React, {FormEvent, useState} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import getMe from "../../../../controllers/users/getMe.ts";
import { apiClient } from "../../../../helpers/apiClient.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./TwoFaAction.module.scss";

import mainStore from "../../../../store/main.ts";
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import classNames from 'classnames';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { showDangerToast } from '../../../../helpers/toasts.helper.ts';

/* Initial form state */
const initialFormState = {
  twoFaCode: ""
};

/* Component */
export default function TwoFaAction() {

  /* Hooks */
  const $navigate = useNavigate();
  const [$searchParams, $setSearchParams] = useSearchParams();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const $breakpoint = useBreakpoint();

  /* Vars */
  const email = $searchParams.get("email") as string;
  if(!email) {
    return (<Navigate to="./" />);
  }

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;

    for(const validation of [
      validate("twoFaCode", formState.twoFaCode)
    ]) {
      if (typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }

    setIsFormPending(true);

    apiClient.post<{ accessToken: string, refreshToken: string }>("/users/fulfillTwoFa", {
      email: email,
      twoFaCode: formState.twoFaCode
    }).then(({ data: {accessToken, refreshToken }}) => {
      mainStore.setAccessToken(accessToken);
      mainStore.setRefreshToken(refreshToken);
      return getMe();
    })
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(styles.TwoFa, 'tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')}>
        <div className="xl:tw-text-3.5xl">Sign in to your account</div>
        <div className="tw-text-sm xl:tw-text-base tw-font-semibold tw-mt-3 xl:tw-mt-4.5">
          Authentication code
        </div>

        <form onSubmit={onFormSubmit} className={styles.form}>
          <TextInput
            placeholder="Enter 6-digit authentication code"
            value={formState.twoFaCode}
            onChange={(value) => setFormState({...formState, twoFaCode: value})}
            isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
          />
          
          <Button
            className="tw-mt-6.25 xl:tw-mt-10 tw-ml-auto"
            additional={{isZeroed: true}}
            disabled={isFormPending}
          >
            <span>Send</span>

            {!isFormPending ? (
              <img src="/icons/arrow-right-background.svg" alt="" />
            ) : (
              <Loader spinsPerSecond={3} />
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import styles from './CompareCardsPage.module.scss';
import classNames from 'classnames';
import { useNavigate } from "react-router-dom";
import {
  CardType,
  CardTypeApplePay,
  CardTypeMastercardPhysical,
  CardTypeVisaPhysical
} from '../../../../services/card-api';
import { cardKindString } from '../../../../helpers/card-kind.helper.ts';
import { CheckmarkStatus } from './components/CheckmartStatus.tsx';
import { logoCardClassMap } from '../../../../helpers/card-brand.helper.ts';
import { getCardTier } from '../../../../helpers/card-tier.helper.ts';
import Button from '../../../../components/Button';
import Select from 'react-select';
import {
  DropdownIndicator
} from '../../../../components/CustomReactSelect/components/DropdownIndicator/DropdownIndicator.tsx';
import { GroupHeading } from '../../../../components/CustomReactSelect/components/GroupHeading/GroupHeading.tsx';
import { getCardFeature, IAvailableCardTypes, ICardFeature } from './card-feature.ts';
import { cardOptionsGroup } from './cards-options.ts';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';
import { Breakpoints, isBreakpointGreaterThan, screenSizesMap } from '../../../../helpers/breakpoints.ts';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import cn from 'classnames';
import { Box } from '@mui/material';

const checkMarkSxProps = {
  gap: '5px',
  justifyContent: 'center',
  [`@media screen and (min-width: ${screenSizesMap[Breakpoints.XL]}px)`]: {
    gap: '10px',
    justifyContent: 'start',
  },
  '.StatusIcon': {
    width: 'auto',
    height: '13px',
    [`@media screen and (min-width: ${screenSizesMap[Breakpoints.XL]}px)`]: {
      height: '20px',
    }
  },
};

export const selectCardTypeSmProps = {
  'div.default-select__control': {
    padding: '5px 7px 5px 10px',
    fontSize: '8px',
    height: '25px',
    minHeight: '25px',
    borderRadius: '5px',
  },
  'div.default-select__indicators': {
    svg: {
      width: '6px',
    }
  },
  'div.default-select__menu': {
    marginTop: '5px',
    borderRadius: '5px',
    fontSize: '8px',
  },
  'div.default-select__menu-list': {
    padding: '5px 0',
  },
  '.default-select__group': {
    padding: '4px 0',
  },
  '.default-select__group-heading': {
    padding: '0 7px',
  },
  'div.default-select__group .default-select__option:nth-of-type(1)': {
    marginTop: '4px',
  },
  'div.default-select__option': {
    padding: '5px 7px 5px',
  }
};

export const SectionSubtitleTableRow = ({ text }: { text: string }) => {
  return (
    <tr className={styles.MobileSectionSubtitle}>
      <td colSpan={2}>
        <div className={styles.SectionProps}>
          <span className={styles.SectionPropsLine}></span>
          <span className={styles.SectionPropsText}>{text}</span>
          <span className={styles.SectionPropsLine}></span>
        </div>
      </td>
    </tr>
  );
};

export const SectionTitleTableRow = ({ text, className }: { text: string, className?: string }) => {
  return (
    <tr className={styles.MobileSectionTitle}>
      <td colSpan={2} className={cn(styles.TableCell, 'tw-text-secondary tw-max-h-max', className)}>
        {text}
      </td>
    </tr>
  )
};

export const GetCardTmpl = ({ onLearnMoreClick, data }: {onLearnMoreClick: () => void, data: ICardFeature }) => {
  const { brandLogo, cardType } = data;
  const cardTier = getCardTier(cardType);
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-1.5 xl:tw-flex-row xl:tw-gap-3.5">
      <Button
        additional={{ isSoftGreen: true }}
        onClick={onLearnMoreClick}
        className={classNames(styles.learnMoreButton)}
      >
        Get new card
      </Button>
      <div className="tw-flex tw-flex-col tw-items-center tw-w-max">
        <img
          src={`/images/${brandLogo.src}`}
          alt={brandLogo.alt}
          className={classNames(styles[`BrandLogo${logoCardClassMap[cardType]}`], 'BrandLogo')}
        />
        {cardTier && <span className={styles.CardTier}>{cardTier}</span>}
      </div>
    </div>
  );
}

const initialSelectedCardTypes = [
  CardTypeMastercardPhysical.PhysicalMastercard,
  CardTypeVisaPhysical.PhysicalVisa,
  CardTypeApplePay.VirtualApplePay,
];

const flatSelectedOption = [...cardOptionsGroup].flatMap(group => [...group.options]);

const CardsComparePage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCardTypes, setSelectedCardTypes] = useState<IAvailableCardTypes[]>(initialSelectedCardTypes);
  const breakpoint = useBreakpoint();

  const handleLearnMoreClick = (cardType: CardType) => {
    navigate(`/app/cards/get-my-card?cardType=${cardType}`);
  };

  const handleSelectCard = (cardType: IAvailableCardTypes, index: number) => {
    setSelectedCardTypes((prevState) => {
      const copyPrevState = [...prevState];
      copyPrevState[index] = cardType;
      return copyPrevState;
    });
  }

  const findSelectedOption = (cardType: CardType) => {
    return flatSelectedOption.find(option => option.value === cardType);
  }

  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);
  const selectedCardFeatures = (isDesktop ? selectedCardTypes : selectedCardTypes.slice(0,2))
    .map((cardType) => getCardFeature(cardType));

  return (
    <PageContainer className={styles.CardsComparePage} isRegular>
      <h1 className="tw-text-1.5xl">Terms & rates</h1>
      <div className={styles.TableWrapper}>
        <table className={cn(styles.CompareTable, 'tw-table-fixed tw-w-full')}>
          <thead>
          <tr>
            <th className="tw-hidden xl:tw-table-cell"></th>
            {selectedCardFeatures.map(({ cardType }, i) => {
              return (
                <th className={classNames(styles.TableCell, 'tw-pt-0 tw-h-auto')} key={i}>
                  <Box sx={!isDesktop ? selectCardTypeSmProps : null}>
                    <Select
                      className={styles.SelectCard}
                      options={cardOptionsGroup}
                      classNamePrefix="default-select"
                      isSearchable={false}
                      value={findSelectedOption(cardType)}
                      onChange={(option: any) => handleSelectCard(option.value, i)}
                      components={{ DropdownIndicator, GroupHeading }}
                    />
                  </Box>
                </th>
              )
            })}
          </tr>
          </thead>
          <tbody>
            <tr className={styles.TableRow}>
              <td
                className={classNames(styles.TableCell, 'tw-hidden tw-align-bottom tw-text-secondary tw-pt-0 xl:tw-table-cell')}>
                Basic information
              </td>
              {selectedCardFeatures.map(({ cardImageScr, cardNetwork }, i) => {
                return (
                  <td className={classNames(styles.TableCell, 'tw-pt-1 xl:tw-pt-0')} key={i}>
                    <img
                      src={`/images/${cardImageScr}`}
                      alt={cardNetwork}
                      className={styles.CardImg}
                    />
                  </td>
                )
              })}
            </tr>

            <SectionTitleTableRow text="Basic information" className="tw-pb-4 tw-pt-6"/>
            <SectionSubtitleTableRow text="Card type"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Card type:</td>
              {selectedCardFeatures.map(({ cardType }, i) =>
                <td className={styles.TableCell} key={i}>{cardKindString(cardType) + ' card'}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Card network"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Card network:</td>
              {selectedCardFeatures.map(({ cardNetwork }, i) =>
                <td className={styles.TableCell} key={i}>{cardNetwork}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Currency"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Currency:</td>
              {selectedCardFeatures.map(({ currency }, i) =>
                <td className={styles.TableCell} key={i}>{currency}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Funding method"/>
            <tr className={cn(styles.TableRow, 'tw-border-b')}>
              <td className={styles.TableCell}>Funding method:</td>
              {selectedCardFeatures.map(({ fundingMethod }, i) =>
                <td className={styles.TableCell} key={i}>{fundingMethod}</td>
              )}
            </tr>

            <SectionTitleTableRow text="Features" className="tw-pb-4 tw-pt-5"/>
            <tr className={cn(styles.TableRow, styles.DesktopSectionTitleRow)}>
              <td colSpan={4}>Features</td>
            </tr>
            <SectionSubtitleTableRow text="Payment for goods and services"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Payment for goods and services</td>
              {selectedCardFeatures.map(({ paymentForGoodsAndServices }, i) =>
                <td className={styles.TableCell} key={i}>
                  <CheckmarkStatus value={paymentForGoodsAndServices} sx={checkMarkSxProps}/>
                </td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Cash withdrawals"/>
            <tr className={cn(styles.TableRow, 'tw-border-b')}>
              <td className={styles.TableCell}>Cash withdrawals</td>
              {selectedCardFeatures.map(({ cashWithdrawals }, i) =>
                <td className={styles.TableCell} key={i}>
                  <CheckmarkStatus value={cashWithdrawals} sx={checkMarkSxProps}/>
                </td>
              )}
            </tr>

            <SectionTitleTableRow text="Limits" className="tw-py-4"/>
            <tr className={cn(styles.TableRow, styles.DesktopSectionTitleRow)}>
              <td colSpan={4}>Limits</td>
            </tr>
            <SectionSubtitleTableRow text="Card Issuance Cost"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Card Issuance Cost</td>
              {selectedCardFeatures.map(({ cardIssuanceCost }, i) =>
                <td className={styles.TableCell} key={i}>{cardIssuanceCost}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Card Top-Up Options"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Card Top-Up Options</td>
              {selectedCardFeatures.map(({ cardTopUpOptions }, i) =>
                <td className={styles.TableCell} key={i}>{cardTopUpOptions}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Transaction Limit"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Transaction Limit</td>
              {selectedCardFeatures.map(({ transactionLimit }, i) =>
                <td className={styles.TableCell} key={i}>{transactionLimit}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Daily Limit"/>
            <tr className={styles.TableRow}>
              <td className={styles.TableCell}>Daily Limit</td>
              {selectedCardFeatures.map(({ dailyLimit }, i) =>
                <td className={styles.TableCell} key={i}>{dailyLimit}</td>
              )}
            </tr>
            <SectionSubtitleTableRow text="Monthly Limit"/>
            <tr className={cn(styles.TableRow, 'tw-border-none')}>
              <td className={styles.TableCell}>Monthly Limit</td>
              {selectedCardFeatures.map(({ monthlyLimit }, i) =>
                <td className={styles.TableCell} key={i}>{monthlyLimit}</td>
              )}
            </tr>
            <tr className={cn(styles.TableRow, 'tw-border-none')}>
              <td className={classNames(styles.TableCell, 'tw-py-0 tw-h-auto')}></td>
              {selectedCardFeatures.map((data, i) =>
                <td className={classNames(styles.TableCell, 'tw-pt-1 tw-pb-0 xl:tw-py-0 tw-h-auto')} key={i}>
                  <GetCardTmpl data={data} onLearnMoreClick={() => handleLearnMoreClick(data.cardType)}/>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </PageContainer>
  );
};

export default CardsComparePage;

import { apiClient } from '../../helpers/apiClient.ts';
import { ILoginDto, ILoginPayload } from './interfaces/login.interface.ts';
import mainStore from "./../../store/main.ts";

export class AuthApiService {
  private readonly _baseUrl: string = '/auth';

  public async login(payload: ILoginPayload): Promise<ILoginDto | string> {
    const response = await apiClient
      .post<ILoginDto>(`${this._baseUrl}/login`, payload);
    mainStore.setAccessToken(response.data.accessToken);
    mainStore.setRefreshToken(response.data.refreshToken);
    return response.data;
  }

  public async refresh(): Promise<ILoginDto> {
    const response = await apiClient.post<ILoginDto>(`${this._baseUrl}/refresh`, {});
    return response.data;
  }

  public async logout(): Promise<void> {
    return await apiClient.post(`${this._baseUrl}/logout`);
  }
}

export const authApiService: AuthApiService = new AuthApiService();

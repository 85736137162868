import { apiClient } from "../../helpers/apiClient.ts";
import { IUserDto } from "./user.interface.ts";

import mainStore from "../../store/main.ts";
import { AxiosResponse } from 'axios';

export default async function getMe(): Promise<IUserDto> {

  const {data: user} = await apiClient.get<IUserDto, AxiosResponse<IUserDto>>("/users/getMe");

  mainStore.setUser(user);

  return user;
}

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CanceledError,
  InternalAxiosRequestConfig
} from "axios";
import config from "../config.ts";
import { showSuccessToast } from './toasts.helper.ts';
import mainStore from "../store/main.ts";
import * as Sentry from "@sentry/react";
import { authApiService } from '../services/auth-api/auth-api.service.ts';
import logout, { clearLogoutData } from '../controllers/users/logout.ts';
import onError from './onError.ts';

export interface IAxiosRequestConfig<D = any> extends AxiosRequestConfig<D> {
  successMessage?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
}

export interface IExtendedInternalAxiosRequestConfig extends InternalAxiosRequestConfig, IAxiosRequestConfig {
}

export const apiClient = axios.create({
  baseURL: config.apiUrl,
}) as AxiosInstance;

// Auth token interceptor
apiClient.interceptors.request.use(
  (config) => {
    const isRefreshRequest = (config?.url ?? '').includes('/auth/refresh');
    const token = isRefreshRequest ? mainStore.refreshToken : mainStore.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

// Log request before send
apiClient.interceptors.request.use((config) => {
    const absoluteUrl: string = [config.baseURL, config.url].filter(Boolean).join('');
    Sentry.addBreadcrumb({
      category: "fetch",
      message: `Request to ${absoluteUrl}`,
      data: {
        method: config.method || 'GET',
        url: absoluteUrl,
        queryParams: config.params,
        requestBody: config.data,
      },
      level: "info",
    });

    return config;
  },
);

// Log request after send
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const absoluteUrl: string = [response.config.baseURL, response.config.url].filter(Boolean).join('');

    Sentry.addBreadcrumb({
      category: "fetch-response",
      message: `Response from ${absoluteUrl}`,
      data: {
        url: absoluteUrl,
        responseStatus: response.status,
        responseBody: response.data,
      },
      level: "info",
    });

    return response;
  },
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

// Refresh token interceptor
let isRefreshing = false;
let failedQueue = [];

function processQueue(error, token: string | null) {
  failedQueue.forEach(promise => {
    if (token) {
      promise.resolve(token);
    } else {
      promise.reject(error);
    }
  });
  failedQueue = [];
}

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = { ...error.config };
    if ((error.response?.status === 401 || error.response?.status === 403)) {
      const isRefreshRequest = originalRequest.url?.includes('/auth/refresh') ?? false;
      if (isRefreshRequest || originalRequest._retry || !mainStore.refreshToken) {
        clearLogoutData();
        isRefreshing = false;
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(() => apiClient(originalRequest))
      }

      isRefreshing = true;

      try {
        const data = await authApiService.refresh();
        console.log('Refresh token response:', data);

        mainStore.setAccessToken(data.accessToken);
        mainStore.setRefreshToken(data.refreshToken);

        processQueue(null, data.accessToken);

        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        processQueue(error, null);
        clearLogoutData();
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Interceptors for handle and show if it needs success messages
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const config = response.config as IExtendedInternalAxiosRequestConfig;
    if (config.successMessage) showSuccessToast(config.successMessage);
    return response;
  },
);

const skipHandleErrorMessage = (error: unknown) => {
  if (error instanceof CanceledError) return true;
  if (error instanceof AxiosError) {
    const isLogoutRequest = error.config?.url?.includes('/auth/logout') ?? false;
    if (isLogoutRequest) return true;
    const isRefreshRequest = error.config?.url?.includes('/auth/refresh') ?? false;
    if (error.response?.status === 401 && !isRefreshRequest) return true;
  }
  return false;
}

// Interceptors for handle errors
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError | CanceledError<any>) => {
    if (!skipHandleErrorMessage(error)) onError(error);
    return Promise.reject(error);
  }
);

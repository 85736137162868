import { FC } from 'react';
import Icon from '../../../../../components/Icon';
import { Box, SxProps } from '@mui/material';
import cn from 'classnames';
import styles from './CheckmarkStatus.module.scss';

interface CheckmarkStatusProps {
  value: boolean;
  sx?: SxProps;
  className?: string;
}

export const CheckmarkStatus: FC<CheckmarkStatusProps> = ({ value, sx, className }) => {
  return (
    <Box component="span" className={cn(styles.CheckMark, 'CheckMark', 'tw-flex tw-items-center', className)} sx={sx}>
      <Icon icon={value ? 'custom-checked' : 'custom-rejected'} className={cn(styles.StatusIcon, 'StatusIcon')} />
      {value ? 'Yes' : 'No'}
    </Box>
  );
};
import { NavigateFunction } from 'react-router-dom';

class NavigationService {
  private navigator: NavigateFunction | null = null;

  public setNavigator(navigator: NavigateFunction) {
    this.navigator = navigator;
  }

  public navigate(path: string) {
    if (this.navigator) {
      this.navigator(path);
    } else {
      console.error('Navigator is not set. Please configure it.');
    }
  }
}

export const navigationService = new NavigationService();

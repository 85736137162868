import { IExchangeRateDto } from './exchange-rate.interface.ts';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../helpers/apiClient.ts';

export class ExchangeApiService {
  public async exchangeRate(): Promise<IExchangeRateDto | null> {
    const { data } = await apiClient.get(`/exchangeRate`) as AxiosResponse<IExchangeRateDto>;
    return data;
  }
}

export const exchangeRateService: ExchangeApiService = new ExchangeApiService();

import {makeAutoObservable} from "mobx";
import {DateTime} from "luxon";
import {makePersistable} from "mobx-persist-store";
import { IUserDto } from '../controllers/users/user.interface.ts';

class MainStore {
  accessToken: string | null = null;
  refreshToken: string | null = null;
  smsConfirmationCooldown: DateTime | null = null;
  user: IUserDto | null = null;

  constructor() {

    makeAutoObservable(this);

    makePersistable(this, {
      name: "mainStore",
      storage: window.localStorage,
      properties: [
        {
          key: "accessToken",
          serialize: (value) => {
            return value ?? "null";
          },
          deserialize: (value) => {
            return value === "null" ? null : value;
          }
        },
        {
          key: "refreshToken",
          serialize: (value) => {
            return value ?? "null";
          },
          deserialize: (value) => {
            return value === "null" ? null : value;
          }
        },
        {
          key: "smsConfirmationCooldown",
          serialize: (value: typeof this.smsConfirmationCooldown) => {
            if(DateTime.isDateTime(value)) {
              return value.toISO();
            }

            return JSON.stringify(value);
          },
          deserialize: (value: string) => {
            if(value === "null") return null;

            return DateTime.fromISO(value);
          }
        } as any
      ]
    });
  }

  setUser(user: typeof this.user) {
    this.user = user;
  }

  setAccessToken(newAccessToken: string | null) {
    this.accessToken = newAccessToken;
  }

  setRefreshToken(newRefreshToken: string | null) {
    this.refreshToken = newRefreshToken;
  }

  setSmsConfirmationCooldown(newCooldown: typeof this.smsConfirmationCooldown) {
    this.smsConfirmationCooldown = newCooldown;
  }

  userFullName(): string {
    if (!this.user) return '';
    return [this.user.firstName, this.user.lastName].filter(name => name).join(" ");
  }
}

export default new MainStore();

import {AxiosError} from "axios";
import { showDangerToast } from './toasts.helper.ts';

export default function onError(error: Error | AxiosError | string) {
  let errorMessage = `Unknown error (${error})`;
  let errorCode: number | null = null;

  if (error instanceof Error) {
    errorMessage = error.message ?? String(error);
  }

  if (error instanceof AxiosError) {
    if (error.code === 'ERR_NETWORK') {
      errorMessage = 'Problem with internet connection! Please try again later';
    } else if (error.response) {
      errorCode = error.response.status;
      if (errorCode === 401) {
        errorMessage = 'Authorization tokens have expired!'
      } else if (errorCode === 403) {
        errorMessage = 'User does not have access to the resource!'
      } else {
        errorMessage = error.response.data?.error || error.response.data?.message;
        if (!errorMessage) {
          if (typeof error.response.data === "string") {
            errorMessage = error.response.data;
          } else {
            errorMessage = JSON.stringify(error.response?.data ?? 'Error');
          }
        }
      }
    } else {
      errorMessage = error.message;
    }
  }

  showDangerToast(errorMessage);
}

import { Divider, Drawer } from '@mui/material';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import Icon from '../Icon';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logout from '../../controllers/users/logout.ts';
import mainStore from '../../store/main.ts';
import { burgerMenuStore } from './BurgerMenu.store.ts';
import classNames from 'classnames';

const signInActions = ['signIn', 'twoFa'];
const signUpActions = ['signUpPhone', 'signUpSmsCode', 'signUpFinish'];

type IBurgerMenuProps = Omit<DrawerProps, 'onClose'> & { onClose: () => void};

export const BurgerMenu: FC<IBurgerMenuProps> = observer((props: IBurgerMenuProps) => {
  const location = useLocation();
  const handleLogout = () => {
    logout();
    burgerMenuStore.closeBurgerMenu();
  };

  const isActiveComparePage = () => {
    return location.pathname.includes('/app/cards/compare');
  }

  const isActiveGetMyCardPage = () => {
    return location.pathname.includes('/app/cards/get-my-card');
  }

  const isActiveCardsPage = () => {
    return location.pathname.includes('/app/cards') && !isActiveGetMyCardPage() && !isActiveComparePage();
  }

  const isActiveSignUpPage = () => {
    const action = new URLSearchParams(location.search).get('action');
    return signUpActions.some(a => a === action);
  }

  const isActiveSignIn = () => {
    const action = new URLSearchParams(location.search).get('action');
    if (!action) return true;
    return signInActions.some(a => a === action);
  }

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      anchor="right"
      sx={{
        backgroundColor: 'rgba(25, 28, 31, 0.4)',
        '.BurgerMenu': {
          width: 'calc(100vw - 30px)',
          maxWidth: '383px',
          '&-Link': {
            fontSize: '24px',
            color: 'var(--vplus-secondary)',
            '&_active': {
              color: 'var(--vplus-black-900)',
            }
          },
          '&-Content': {
            marginTop: '58px',
          },
        }
      }}
    >
      <div className="BurgerMenu tw-flex tw-flex-col tw-flex-1">
        <div className="tw-flex tw-justify-between tw-gap-4 tw-pt-7.5 tw-pl-7 tw-pr-8">
          <img src="/vplus-dark-full.svg" className="tw-h-6.25 tw-w-auto" alt="vplus-logo"/>
          <button onClick={() => props.onClose && props.onClose()}>
            <Icon icon="close" width="14px"/>
          </button>
        </div>
        <div className="BurgerMenu-Content tw-flex tw-flex-col tw-gap-4.5 tw-flex-1">
          <nav className="tw-flex tw-flex-col tw-gap-7 tw-pl-7 tw-pr-8">
            <ul className="tw-flex tw-flex-col tw-gap-7">
              {
                mainStore.user &&
                  <>
                    <li>
                      <NavLink
                        to="/app/cards"
                        className={({ isActive }) =>
                          isActive && isActiveCardsPage()
                            ? "BurgerMenu-Link BurgerMenu-Link_active"
                            : "BurgerMenu-Link"
                        }
                        onClick={() => burgerMenuStore.closeBurgerMenu()}
                      >
                          Му cards
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/app/cards/get-my-card"
                        className={({ isActive }) =>
                          isActive && isActiveGetMyCardPage()
                            ? "BurgerMenu-Link BurgerMenu-Link_active"
                            : "BurgerMenu-Link"
                        }
                        onClick={() => burgerMenuStore.closeBurgerMenu()}
                      >
                          Get new card
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/app/cards/compare"
                        className={({ isActive }) =>
                          isActive && isActiveComparePage()
                            ? "BurgerMenu-Link BurgerMenu-Link_active"
                            : "BurgerMenu-Link"
                        }
                        onClick={() => burgerMenuStore.closeBurgerMenu()}
                      >
                          Terms & rates
                      </NavLink>
                    </li>
                  </>
              }
              {
                !mainStore.user &&
                  <>
                    <NavLink
                        to="/login"
                        className={({ isActive }) =>
                          isActive && isActiveSignIn()
                            ? "BurgerMenu-Link BurgerMenu-Link_active"
                            : "BurgerMenu-Link"
                        }
                        onClick={() => burgerMenuStore.closeBurgerMenu()}
                    >
                        Sign in
                    </NavLink>
                    <NavLink
                      to="/login/?action=signUpPhone"
                      className={({ isActive }) =>
                        isActive && isActiveSignUpPage()
                          ? "BurgerMenu-Link BurgerMenu-Link_active"
                          : "BurgerMenu-Link"
                      }
                      onClick={() => burgerMenuStore.closeBurgerMenu()}
                    >
                        Sign up
                    </NavLink>
                  </>
              }
            </ul>
          </nav>
          {mainStore.user &&
              <nav className="tw-flex tw-flex-col tw-justify-end tw-flex-1">
                <div>
                  <Divider component="div" sx={{ borderColor: 'var(--vplus-secondary)' }}/>
                  <div className="tw-pl-7 tw-pr-8 tw-py-4.5">
                    <NavLink
                      to="/app/profile"
                      className={({ isActive }) => 'BurgerMenu-Link' + (isActive ? ' BurgerMenu-Link_active' : '')}
                      onClick={() => burgerMenuStore.closeBurgerMenu()}
                    >
                        User profile
                    </NavLink>
                  </div>
                  <Divider component="div" sx={{ borderColor: 'var(--vplus-secondary)' }}/>
              </div>
              <div className="tw-pl-7 tw-pr-8 tw-pt-4.5 tw-pb-16">
                <button className="tw-text-start tw-text-danger tw-text-2xl" onClick={() => handleLogout()}>
                  Log out
                </button>
              </div>
            </nav>
          }
        </div>
      </div>
    </Drawer>
  );
});

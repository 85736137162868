import { observer, useLocalObservable } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { actionButtons, ActionType, IActionButton } from './components/ActionButtons/action-buttons.ts';
import { CardStatus, ICardDto } from '../../../../services/card-api/interfaces/card.interface.ts';
import ActionButton from './components/ActionButtons/ActionButton.tsx';
import styles from './MyCards.module.scss';
import { Card } from './../../Cards/components/Card/Card.tsx';
import { CardsStore, createCardDetailStore, createCardsStore, createCardTransactionsStore } from './store';
import { CardTransactions } from './../../Cards/components/CardTransactions/CardTransactions.tsx';
import { Modal } from './../../../../components/Modal';
import { CardPinDialogContent } from './../../Cards/components/CardPinDialogContent/CardPinDialogContent.tsx';
import { MyCardsSelector } from './../../Cards/MyCards/components/MyCardsSelector/MyCardsSelector.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import classNames from 'classnames';
import { isVirtualCard } from '../../../../helpers/card-kind.helper.ts';
import { ActivateCardOverlap } from '../components/ActivateCardOverlap/ActivateCardOverlap.tsx';
import { ICardDataActiveDto } from '../../../../services/card-api';
import { PageContainer } from '../../../../components/PageContainer/PageContainer.tsx';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import cn from 'classnames';
import { myCardSmSXProps, cardSmSXProps } from './card-sm-sx-props.ts';

interface MyCardsPageProps {
  cardsStore?: CardsStore;
}

const MyCardsPage: FC<MyCardsPageProps> = observer(({ cardsStore: inputCardStore }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const cardsStore = inputCardStore ?? useLocalObservable(() => createCardsStore());
  const transactionsStore = useLocalObservable(() => createCardTransactionsStore());
  const activeCardStore = useLocalObservable(() => createCardDetailStore());
  const [filteredActionButtons, setActionButtons] = useState([] as IActionButton[]);
  const [filteredCards, setFilteredCards] = useState<ICardDto[]>([]);
  const [isOpenCardDetailsDialog, setOpenCardDetailsDialog] = useState(false);
  const [isOpenChangePinDialog, setOpenChangePinDialog] = useState(false);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (activeCardStore.card?.id) {
      transactionsStore.loadCardTransactions(activeCardStore.card.id, { pageSize: 6, pageNumber: 1 });
    }
  }, [activeCardStore.card?.id]);
  
  useEffect(() => {
    cardsStore.loadCards();
  }, []);
  
  useEffect(() => {
    const filteredCards = cardsStore.cards.filter((card) => card.status === CardStatus.ACTIVE);
    if (!activeCardStore.card?.id && filteredCards.length) {
      const candidateCardId = searchParams.get('cardId') ?? '';
      const activeCard: ICardDto = [...filteredCards].find(({ id }) => id === candidateCardId) ?? filteredCards[0];
      activeCardStore.setCard(activeCard);
      updateSearchParams(activeCard.id)
    }
    setFilteredCards(filteredCards);
   
  }, [cardsStore.cards]);
  
  useEffect(() => {
    const activeCardId = activeCardStore.card?.id;
    if (activeCardId) activeCardStore.loadCardData(activeCardId);
  }, [activeCardStore.card?.id]);
  
  useEffect(() => {
    if (activeCardStore.card?.id) {
      cardsStore.updateCard(activeCardStore.card.id, { freezed: activeCardStore.card?.freezed })
    }
    setActionButtons(filterActionButtons(actionButtons, activeCardStore.cardData));
  }, [activeCardStore.cardData, activeCardStore.isLoadingLock, activeCardStore.loading]);

  const isDesktop = isBreakpointGreaterThan(breakpoint, Breakpoints.XL);
  
  const updateSearchParams = (cardId: string) => {
    const params = new URLSearchParams({...searchParams});
    params.set('cardId', cardId);
    setSearchParams(params, { replace: true });
  };
  
  const handleCardSelectorClick = (card: ICardDto) => {
    activeCardStore.setCard(card);
    updateSearchParams(card.id);
  }
  
  const handleChangedPin = () => {
    if (activeCardStore.card) {
      cardsStore.updateCard(activeCardStore.card.id, { pinNotSet: false });
      activeCardStore.updateCardData({ pinNotSet: false });
    }
  };
  
  const handleActionClick = useCallback((type: ActionType) => {
    if (activeCardStore.card) {
      switch (type) {
        case 'lock':
          activeCardStore.lockCard(activeCardStore.card.id);
          break;
        case 'unlock':
          activeCardStore.unlockCard(activeCardStore.card.id);
          break;
        case 'details':
          setOpenCardDetailsDialog(true);
          break;
        case "setPin":
          setOpenChangePinDialog(true);
          break;
        case "topUp":
          navigate(`${activeCardStore.card.id}/top-up`)
          break;
      }
    }
  }, []);
  
  const filterActionButtons = (buttons: IActionButton[], card: ICardDataActiveDto | null) => {
    if (!card) return [];
    const { cardType, freezed } = card;
    return buttons.filter((item) => {
      if (item.type === 'setPin' && isVirtualCard(cardType)) return false;
      if (item.type !== 'lock' && item.type !== 'unlock') return true;
      if (item.type === 'lock' && !freezed) return true;
      return item.type === 'unlock' && freezed;
    }).map((item: IActionButton) => {
      if (activeCardStore.isLoadingLock) return {...item, disabled: true };
      if (activeCardStore.cardData?.freezed && item.type !== 'unlock') return {...item, disabled: true };
      if (activeCardStore.loading || !activeCardStore.cardData) return {...item, disabled: true };
      if ((item.type === 'lock' || item.type === 'unlock') && activeCardStore.isLoadingLock) {
        return {...item, disabled: activeCardStore.isLoadingLock };
      }
      return {...item};
    });
  };
  
  return (
    <PageContainer className={styles.Page} isRegular>
      <div>
        <section className={styles.CardSection}>
          <div className={classNames(styles.cardWrapper, 'tw-flex-centering tw-relative')}>
            {!cardsStore.loading && activeCardStore.card && (
              <Card
                isLocked={activeCardStore.cardData?.freezed}
                cardType={activeCardStore.card.cardType}
                cardData={activeCardStore?.cardData}
                cardBalance={activeCardStore?.balance}
                containerClassNames="tw-relative"
                sx={!isDesktop ? myCardSmSXProps : null}
              >
                {
                  activeCardStore.cardData?.pinNotSet &&
                    <ActivateCardOverlap onClickSetPin={() => handleActionClick('setPin')}/>
                }
              </Card>
            )}
            {!cardsStore.loading && !filteredCards.length && (
              <p>No card data available</p>
            )}
            {(cardsStore.loading || activeCardStore.loading) && <div className="tw-position-centering"><Loader/></div>}
          </div>
          {isDesktop &&
              <div className="tw-flex tw-flex-1">
                  <CardTransactions
                      cardId={activeCardStore.card?.id}
                      transactions={transactionsStore.transactions}
                      isLoading={transactionsStore.loading || !activeCardStore.card?.id}
                  ></CardTransactions>
              </div>
          }
        </section>
        <div className={cn(styles.CardsControls, 'tw-mt-5 xl:tw-mt-8')}>
          {!cardsStore.loading && filteredCards.length > 1 && (
            <section className={styles.MyCardsMini}>
              <h5 className="tw-text-xs xl:tw-text-base">My cards</h5>
              <div className="tw-mt-2.5 xl:tw-mt-8 tw-overflow-auto">
                <MyCardsSelector
                  cards={filteredCards}
                  activeCard={activeCardStore.card}
                  onCardClick={(card: ICardDto) => handleCardSelectorClick(card)}
                  sx={{ overflow: 'auto' }}
                ></MyCardsSelector>
              </div>
            </section>
          )
          }
          <section className={cn(styles.CardActions, 'tw-flex-1 tw-gap-2.5 xl:tw-gap-5')}>
            {filteredActionButtons.map((button) => (
              <ActionButton
                key={button.type}
                icon={button.icon}
                text={button.text}
                disabled={button.disabled}
                pinNotSet={activeCardStore.cardData?.pinNotSet}
                onClick={() => handleActionClick(button.type)}
                sx={{ '.actionButton': { width: '100%' } }}
              />
            ))}
          </section>
        </div>

        {!isDesktop &&
            <CardTransactions
                cardId={activeCardStore.card?.id}
                transactions={transactionsStore.transactions}
                isLoading={transactionsStore.loading || !activeCardStore.card?.id}
                className="tw-mt-5"
            ></CardTransactions>
        }

        {isOpenCardDetailsDialog && activeCardStore.card?.cardType &&
            <Modal
                isOpen={isOpenCardDetailsDialog}
                className="tw-p-0 tw-bg-transparent"
                onRequestClose={() => setOpenCardDetailsDialog(false)}
            >
                <Card
                    cardType={activeCardStore.card.cardType}
                    cardData={activeCardStore?.cardData}
                    cardBalance={activeCardStore?.balance}
                    isShowCvv={!!activeCardStore.cardData?.cvv}
                    sx={!isDesktop ? myCardSmSXProps : null}
                    isMaskedData={false}
                ></Card>
            </Modal>
        }

        {isOpenChangePinDialog && activeCardStore.card?.id &&
            <Modal
                isOpen={isOpenChangePinDialog}
                className="tw-h-auto tw-min-w-0 tw-min-h-0 tw-p-0"
                onRequestClose={() => setOpenChangePinDialog(false)}
            >
                <CardPinDialogContent
                    cardId={activeCardStore.card.id}
                    onChangedPin={handleChangedPin}
                ></CardPinDialogContent>
            </Modal>
        }
      </div>
    </PageContainer>
  );
});

export default MyCardsPage;

import { apiClient } from "../../helpers/apiClient.ts";
import validate from "../../helpers/validate.ts";
import { showDangerToast } from '../../helpers/toasts.helper.ts';

interface ICreateUserPayload {
  email: string,
  phoneNumber: string,
  password: string,
  firstName: string,
  lastName: string,
}

export default async function createUser(opt: ICreateUserPayload, smsToken: string): Promise<void> {

  /* Validate client data */
  for(const validation of [
    validate("phoneNumber", opt.phoneNumber),
    validate("email", opt.email),
    validate("password", opt.password),
    validate("name", opt.firstName, 'First name'),
    validate("name", opt.lastName, 'Last name'),
  ]) {
    if(typeof validation === "string") {
      showDangerToast(validation);
      throw new Error(validation);
    }
  }

  /* Send API request */
  await apiClient.post("/users/create", {
    ...opt,
    smsToken
  });
}

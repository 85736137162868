import { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './GetMyCardBlock.module.scss';
import classNames from 'classnames';
import Button from '../../../../../../components/Button';

interface IGetMyCardBlockProps {
  onClickGetMyCard?: () => void;
  isDisabledBtn?: boolean;
  className?: string;
}

const GetMyCardBlock: FC<IGetMyCardBlockProps> = ({
  onClickGetMyCard,
  isDisabledBtn,
  className,
}) => {
  return (
    <section className={className}>
      <div className={styles.container}>
        <p className={styles.description}>
          <span>Begin with a short KYC form to receive your card — it's fast and convenient.</span>
          <span>Your card is just a step away!</span>
        </p>
        <button
          className={classNames(styles.ctaButton, 'tw-bg-softGreen')}
          onClick={onClickGetMyCard}
          disabled={isDisabledBtn}
        >
          Get new card
        </button>
      </div>
      <Button
        className="tw-block xl:tw-hidden tw-mt-3.5"
        onClick={onClickGetMyCard}
        disabled={isDisabledBtn}
      >
        Get new card
      </Button>
    </section>
  );
};

export default GetMyCardBlock;

import { apiClient } from '../../helpers/apiClient.ts';

export class ChangePasswordApiService {
  public async changePassword(payload: IChangePasswordPayload): Promise<void> {
    await apiClient.post('/users/changePassword', { ...payload });
  }
  
  public async validatePassword(password: string): Promise<{valid: boolean}> {
    return (await apiClient.post<{valid: boolean}>('/users/validateCurrentPassword', { password }))?.data;
  }
}

export interface IChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const changePasswordApiService: ChangePasswordApiService = new ChangePasswordApiService();

import classNames from "classnames";
import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import getMe from "../../../../controllers/users/getMe.ts";
import { apiClient } from "../../../../helpers/apiClient.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./SignInAction.module.scss";
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { authApiService } from '../../../../services/auth-api/auth-api.service.ts';
import { showDangerToast } from '../../../../helpers/toasts.helper.ts';

/* Initial form state */
const initialFormState = {
  email: "",
  password: ""
};

/* Component */
export default function SignInAction() {

  /* Hooks */
  const $navigate = useNavigate();
  const [$searchParams, $setSearchParams] = useSearchParams();
  const $breakpoint = useBreakpoint();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  /* Vars */
  const emailToken = $searchParams.get("emailToken") as string;
  const isJustActivated = !!emailToken;

  /* On mount, verify email (if applicable) */
  useEffect(() => {
    if (emailToken) {
      apiClient.post("/emailConfirmations/fulfill", {
        emailToken
      })
    }
  }, []);

  /* On form submit function */
  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (isFormPending) return;

    for (const validation of [
      validate("email", formState.email),
      validate("password", formState.password)
    ]) {
      if (typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }

    setIsFormPending(true);

    authApiService.login({
      email: formState.email,
      password: formState.password
    })
      .then((data) => {
        /* If 2FA */
        if (typeof data === "string") {
          $navigate(`./?action=twoFa&email=${formState.email}`);

          return;
        }

        return getMe();
      })
      .finally(() => setIsFormPending(false));
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(isJustActivated && styles.ActivationBlock, styles.SignInActionBlock, 'tw-bg-secondary-75')}>

        {/* Just activated congrats */}
        {isJustActivated ? (
          <>
            <img src="/icons/cirle-check-1.svg" style={{width: 70}} alt="circle check" />
            <div className="tw-text-xl xl:tw-text-3.5xl tw-mt-5">
              Congratulations!
            </div>
            <div className={classNames(styles.TextMessage, "tw-text-2sm tw-mt-4 xl:tw-text-base")}>
              Your account has been successfully activated. Welcome to the VPlus!
            </div>
            
            <div className={classNames(styles.TextMessage, "tw-text-2sm tw-mt-4 xl:tw-text-base")}>
              We’re excited to have you on board. Feel free to log in now and discover the multitude of features and
              benefits awaiting you as a valued member.
            </div>
            
            <div className="tw-border-secondary tw-border-t tw-my-7 xl:tw-my-10" />
          </>
        ) : null}

        {/* Base Sign In */}
        <div className="xl:tw-text-3.5xl">
          Sign in to your account
        </div>

        <form className="tw-pt-6.5 xl:tw-pt-5" onSubmit={onFormSubmit}>
          <TextInput
            placeholder="Email"
            value={formState.email}
            onChange={(value) => setFormState({...formState, email: value})}
            isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
            isReadOnly={isFormPending}
          />

          <div className="tw-pt-6.5 xl:tw-pt-4">
            <TextInput
              type="password"
              placeholder="Password"
              value={formState.password}
              onChange={(value) => setFormState({...formState, password: value})}
              isBigger={isBreakpointGreaterThan($breakpoint, Breakpoints.XL)}
              isReadOnly={isFormPending}
            />
          </div>



          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-mt-8 xl:tw-mt-11">
            <Button
              type="button"
              className="tw-h-auto"
              additional={{isZeroed: true}}
              onClick={() => $navigate(`./?action=passwordReset`)}
              disabled={isFormPending}
            >
              <span className="tw-underline tw-text-sm xl:tw-text-base">Forgot password?</span>
            </Button>
            <Button additional={{isZeroed: true}} disabled={isFormPending}>
              <span>Confirm</span>

              {!isFormPending ? (
                <img src="/icons/arrow-right-background.svg" alt="" />
              ) : (
                <Loader spinsPerSecond={3} />
              )}
            </Button>
          </div>
        </form>
      </div>

      {/* Green block */}
      {!isJustActivated &&
        <div
          className={classNames(styles.NoRegisterBlock, "tw-flex tw-items-center tw-mt-6 tw-bg-softGreen")}
        >
          <img src="/icons/face-1.svg" alt="face" />
          <div className="tw-flex tw-flex-col tw-gap-3 tw-w-full xl:tw-flex-row xl:tw-items-center">
            <div className="tw-w-full">
              <div className="tw-text-xl xl:tw-text-3.5xl">Not registered?</div>
              <div className="tw-mt-2 tw-text-sm xl:tw-text-base">Create an account in one click right now</div>
            </div>
            <Button
              className={classNames(styles['NoRegisterBlock-Btn'])}
              onClick={() => $navigate("./?action=signUpPhone")}
            >
              Sign Up
            </Button>
          </div>
        </div>
      }
    </div>
  );
}

import { DateTime } from "luxon";
import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import TextInput from "../../../../components/TextInput";
import { apiClient } from "../../../../helpers/apiClient.ts";
import validate from "../../../../helpers/validate.ts";
import styles from "./SignUpPhoneAction.module.scss";

import mainStore from "../../../../store/main.ts";
import Select2 from '../../../../components/Select2';
import { phoneCodeOptions } from '../../../../consts/phone-code-options.ts';
import { useBreakpoint } from '../../../../hooks/useBreakpoint.ts';
import { Breakpoints, isBreakpointGreaterThan } from '../../../../helpers/breakpoints.ts';
import classNames from 'classnames';
import { showDangerToast, showSuccessToast } from '../../../../helpers/toasts.helper.ts';

/* Initial form state */
const initialFormState = {
  phoneNumber: "",
  phoneCode: "+44",
};

/* Component */
export default function SignUpPhoneAction() {

  /* Hooks */
  const $navigate = useNavigate();
  const $breakpoint = useBreakpoint();

  /* State */
  const [formState, setFormState] = useState<typeof initialFormState>(initialFormState);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [isBiggerControls, setIsBiggerControls] = useState<boolean>(false);

  useEffect(() => {
    setIsBiggerControls(isBreakpointGreaterThan($breakpoint, Breakpoints.XL));
  }, [$breakpoint])

  /* On form submit function */
  async function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) return;

    const phoneNumber = formState.phoneCode + formState.phoneNumber;

    for(const validation of [
      validate("phoneNumber", phoneNumber)
    ]) {
      if (typeof validation === "string") {
        showDangerToast(validation);
        return;
      }
    }

    setIsFormPending(true);

   apiClient.post("/smsConfirmations/create", { phoneNumber })
    .then(() => {
      showSuccessToast('We\'ve sent SMS code');
      mainStore.setSmsConfirmationCooldown(DateTime.now().plus({minutes: 3}));
      $navigate(`./?action=signUpSmsCode&phoneNumber=${encodeURIComponent(phoneNumber)}`);
    })
    .finally(() => {
      setIsFormPending(false);
    });
  }

  /* DOM */
  return (
    <div>
      <div className={classNames(styles.CreateAccount, 'tw-bg-secondary-75 tw-rounded-xl xl:tw-rounded-2xl')}>
        <div className="xl:tw-text-3.5xl">Create your account</div>
        <form onSubmit={onFormSubmit} className={styles.CreateAccountForm}>
          <label htmlFor="phoneNumber" className="tw-inline-block tw-text-secondary tw-text-sm tw-mb-2.5 xl:tw-hidden">
            Enter your phone number
          </label>
          <div className={styles.phoneRow}>
            <Select2
              options={phoneCodeOptions}
              selected={phoneCodeOptions.find((opt) => opt.value === formState.phoneCode)}
              onSelect={(option) => setFormState({ ...formState, phoneCode: option.value })}
              disabled={isFormPending}
              isBigger={isBiggerControls}
            />
            <TextInput
              id="phoneNumber"
              placeholder={isBiggerControls ? 'Enter your phone number' : ''}
              value={formState.phoneNumber}
              onChange={(value) => setFormState({ ...formState, phoneNumber: value })}
              disabled={isFormPending}
              isBigger={isBiggerControls}
              floatingPlaceholder={isBiggerControls}
            />
          </div>
          
          <Button className="tw-mt-6.25 xl:tw-mt-10 tw-ml-auto" additional={{ isZeroed: true }} disabled={isFormPending}>
            <span>Next step</span>
            
            {!isFormPending ? (
              <img className="tw-size-8.5" src="/icons/arrow-right-background.svg" alt=""/>
            ) : (
              <Loader spinsPerSecond={3}/>
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}

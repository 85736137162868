import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import styles from "./Footer.module.scss";

import mainStore from "../../store/main.ts";
import logout from '../../controllers/users/logout.ts';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Icon from '../Icon';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';

function Footer() {
  const [expanded, setExpanded] = useState<string | null>();

  const handleChange = (panel: string) => (_, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <footer className={classNames(styles.Footer, 'tw-bg-black-700 tw-text-white')}>
      <div className={styles['Footer-InnerContainer']}>
        <div className="container-xl tw-mx-auto">
          <div className={styles['Footer-Header']}>
            Save more, pay less
          </div>

          <div className={styles['Footer-GroupsContainer']}>
            <div className={classNames(styles['Footer-NavMenuDesktop'], 'tw-hidden xl:tw-flex')}>
              {mainStore.user &&
                  <>
                      <div>
                          <div>VPlus</div>
                          <div className="tw-flex tw-flex-col tw-text-secondary-800 tw-mt-3.5 tw-gap-3.5">
                              <Link to="/app/cards" className="tw-w-auto">Му cards</Link>
                              <Link to="/app/cards/get-my-card" className="tw-w-max">Get new card</Link>
                              <Link to="/app/cards/compare" className="tw-w-max">Terms & rates</Link>
                          </div>
                      </div>

                      <div>
                          <div>Account</div>
                          <div className="tw-flex tw-flex-col tw-text-secondary-800 tw-mt-3.5 tw-gap-3.5">
                              <Link to="/app/profile" className="tw-w-max">User profile</Link>
                              <Link to="/app/security/changePassword" className="tw-w-max">Security</Link>
                              <button className="tw-text-start tw-text-danger tw-w-max" onClick={() => logout()}>
                                  Log out
                              </button>
                          </div>
                      </div>
                  </>
              }
              {!mainStore.user &&
                <div>
                  <div>Account</div>
                  <div
                    className="tw-flex tw-flex-col  tw-text-secondary-800 tw-mt-3.5 tw-gap-3.5">
                    <Link to="/login" className="tw-w-max">Sign in</Link>
                    <Link to="/login/?action=signUpPhone" className="tw-w-max">Sign up</Link>
                  </div>
                </div>
              }
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 xl:tw-hidden">
              {mainStore.user &&
                <Accordion
                  expanded={expanded === 'vplus-panel'}
                  className="AccordionDefault tw-text-white"
                  onChange={handleChange('vplus-panel')}
                >
                  <AccordionSummary expandIcon={<Icon icon="expand-arrow-bottom" width="14px" stroke="white"/>}>
                    VPlus
                  </AccordionSummary>
                  <AccordionDetails className="tw-mt-2.5">
                    <div className="tw-flex tw-flex-col tw-text-secondary-800 tw-gap-2.5">
                      <Link to="/app/cards" className="tw-w-auto">Му cards</Link>
                      <Link to="/app/cards/get-my-card" className="tw-w-max">Get new card</Link>
                      <Link to="/app/cards/compare" className="tw-w-max">Terms & rates</Link>
                    </div>
                  </AccordionDetails>
                </Accordion>
              }
              <Accordion
                expanded={expanded === 'account-panel'}
                className="AccordionDefault tw-text-white"
                onChange={handleChange('account-panel')}
              >
                <AccordionSummary expandIcon={<Icon icon="expand-arrow-bottom" width="14px" stroke="white"/>}>
                  Account
                </AccordionSummary>
                <AccordionDetails className="tw-mt-2.5">
                  <div className="tw-flex tw-flex-col tw-text-secondary-800 tw-gap-2.5">
                    {mainStore.user &&
                      <>
                        <Link to="/app/profile" className="tw-w-max">User profile</Link>
                        <Link to="/app/security/changePassword" className="tw-w-max">Security</Link>
                        <button className="tw-text-start tw-text-danger tw-w-max" onClick={() => logout()}>
                          Log out
                        </button>
                      </>
                    }
                    {!mainStore.user &&
                      <>
                        <Link to="/login" className="tw-w-max">Sign in</Link>
                        <Link to="/login/?action=signUpPhone" className="tw-w-max">Sign up</Link>
                      </>
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="tw-mt-10 xl:tw-mt-0 xl:tw-text-end">
              <div>Address</div>
              <div
                className={
                  classNames(
                    styles.AddressInfo, 'tw-mt-2.5 xl:tw-mt-3.5 tw-leading-5 tw-font-semibold tw-text-secondary-800'
                  )}
              >
                HONG KONG, FLAT 2, 11/F, YUEN LONG TRADE CENTRE, 99-109 CASTLE PEAK ROAD, YUEN LONG, NEW TERRITORIES.
              </div>

              <div className="tw-mt-4 xl:tw-mt-5">
                <a className="tw-underline tw-text-softGreen" href="mailto:info@vpluspay.hk">
                  cards@vpluspay.hk
                </a>
              </div>
            </div>
          </div>

          {/* Footer about company */}
          <div className={
            classNames(
              styles['Footer-AboutCompany'],
              'tw-flex tw-flex-col tw-items-start tw-gap-4 xl:tw-flex-row xl:tw-items-center xl:tw-gap-6.25'
            )}>

            <img className="tw-h-6.25 tw-w-auto" src="/vplus-light-full.svg" alt="Vplus full logo"/>

            <div className="tw-text-secondary-800">
              &copy; VPlus Greater Bay Exchange Limited 2024. All rights reserved.
            </div>
            <div className="tw-hidden tw-flex-1 tw-justify-end xl:tw-flex">
              <img className="tw-w-auto tw-h-6.25" src="/logo-green.svg" alt="VPlus green logo"/>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles['Footer-DividerWrapper'])}>
        <div className="container-xl tw-mx-auto">
          <div className="tw-border-b tw-border-b-secondary-800"></div>
        </div>
      </div>

      <div className={classNames(styles['Footer-InnerContainer'])}>
        <div className="container-xl tw-mx-auto">
          <div className="tw-text-xs xl:tw-text-base">
            This website is the sole property of VPLUS GREATER BAY EXCHANGE LIMITED,
            duly registered in Hong Kong at FLAT 2, 11/F, YUEN LONG TRADE CENTRE, 99-109 CASTLE PEAK ROAD, YUEN LONG,
            NEW TERRITORIES. The content presented on this website is provided for general informational purposes only
            and may not always be entirely accurate. It should be noted that not all products and services offered by
            VPLUS GREATER BAY EXCHANGE LIMITED are universally accessible and may be subject to geographical
            restrictions
            and specific terms and conditions. The fees associated with products and services offered by VPLUS GREATER
            BAY EXCHANGE LIMITED are subject to potential revisions.Users are advised to exercise caution and vigilance
            to guard against potential fraudulent activities.
          </div>

          <div className={classNames(styles['Footer-InfoLinks'], 'tw-flex tw-items-center tw-text-secondary-800')}>
            <Link to="/terms" className="tw-font-semibold tw-underline tw-mb-0">Terms and conditions</Link>
            <Link to="/privacy" className="tw-font-semibold tw-underline tw-mb-0">Privacy Policy &amp; Cookies</Link>
            <Link to="/aml" className="tw-font-semibold tw-underline tw-mb-0">AML</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default observer(Footer);
